import React from "react";
import { useState, useEffect, useCallback } from "react";
import { classNames } from "../utils";
import { useQuery } from "@apollo/client";
import { SUCCESS_STORIES, SuccessStory } from "../utils/graphql";
import CaseStudies from "./CaseStudies";
import Variant from "./Variant";
import { LockedOrLoadedWithSession as Icon } from "./LoadingOrLockedIcon";
import * as R from "ramda";

export type Payload = { [index: string]: any };

interface State {
  data: Object;
  loading: boolean;
}
interface Action {
  type: string;
  payload: Payload;
}

const initState: State = { data: new Object(), loading: false };

const Context = React.createContext([
  initState,
  (state: State, action: Action): any => {},
] as any);

export const useStepContext = (initValues?: Object) => {
  const [state, dispatch] = React.useContext(Context);
  useEffect(() => {
    if (initValues && state.data != initValues) {
      dispatch({ type: "UPDATE", payload: initValues });
    }
  }, []);

  return [state, dispatch];
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "UPDATE":
      const out = {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        loading: false,
      };
      return out;
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "NOT_LOADING":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const StepsContainer = ({
  setWizardValue,
  children,
}: {
  setWizardValue?: (v: any) => void;
  children?: React.ReactElement;
}) => {
  const [state, dispatch] = React.useReducer(reducer, initState);

  useEffect(() => {
    if (setWizardValue) {
      setWizardValue(state.data);
    }
  }, [state.data]);

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Header = ({
  primary,
  secondary,
}: {
  primary: string;
  secondary?: string;
}) => {
  if (primary == undefined) {
    return null;
  }
  return (
    <div className="mt-10 mb-14">
      <h3 className="text-lg font-medium leading-6 text-gray-900 text-center">
        {primary}
      </h3>
      {secondary && (
        <p className="mt-1 text-sm text-gray-500 text-center">{secondary}</p>
      )}
    </div>
  );
};

export const StepContainerRaw = ({
  next,
  loading,
  children,
  buttonCopy,
  buttonContainerClass,
  hideButton,
}: {
  next: (value?: any) => void;
  loading?: boolean;
  buttonCopy?: string;
  children?: React.ReactElement;
  buttonContainerClass?: string;
  hideButton?: boolean;
}) => {
  const [state] = useStepContext();
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          next();
        }}
      >
        {children}
        {!hideButton && !loading && (
          <div className={buttonContainerClass}>
            <button
              disabled={loading}
              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <Icon loading={state.loading} />
              {buttonCopy ? buttonCopy : "Next Step"}
            </button>
          </div>
        )}
      </form>
    </>
  );
};

export const StepContainer = ({
  primary,
  secondary,
  next,
  loading,
  children,
  buttonCopy,
}: {
  primary?: string;
  secondary?: string;
  next: () => void;
  loading?: boolean;
  buttonCopy?: string;
  children?: React.ReactElement;
}) => {
  const [state] = useStepContext();
  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md">
      <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
        {primary && <Header primary={primary} secondary={secondary} />}
        <StepContainerRaw
          next={next}
          loading={loading}
          buttonCopy={buttonCopy}
          buttonContainerClass="bg-gray-50 px-4 py-3 text-right sm:px-6"
        >
          {children}
        </StepContainerRaw>
      </div>
    </div>
  );
};

export const TextEntryStep = ({
  id,
  primary,
  placeholder,
}: {
  id: string;
  primary?: string;
  placeholder?: string;
}) => {
  const [state, dispatch] = useStepContext();

  const setEntryValue = (value: string) => {
    //if (R.isNil(value) || value == "") {
    //value = placeholder || "";
    //}
    const obj: Payload = {};
    obj[id] = value;
    dispatch({ type: "UPDATE", payload: obj });
  };

  return (
    <>
      {primary && <Header primary={primary} />}
      <textarea
        disabled={state.loading}
        value={state.data[id]}
        onChange={(e) => {
          setEntryValue(e.target.value);
        }}
        rows={20}
        name={id}
        id={id}
        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        placeholder={placeholder}
      />
    </>
  );
};

export const EntryStep = ({
  id,
  primary,
}: {
  id: string;
  primary?: string;
}) => {
  const [state, dispatch] = useStepContext();

  const setEntryValue = (value: string) => {
    const obj: Payload = {};
    obj[id] = value;
    dispatch({ type: "UPDATE", payload: obj });
  };

  return (
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-6">
        {primary && (
          <label
            htmlFor="street-address"
            className="block text-sm font-medium text-gray-700"
          >
            {primary}
          </label>
        )}
        <input
          disabled={state.loading}
          value={state.data[id]}
          onChange={(e) => {
            setEntryValue(e.target.value);
          }}
          type="text"
          name={id}
          id={id}
          className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  );
};

export const SelectStep = ({
  id,
  primary,
  options,
}: {
  id: string;
  primary?: string;
  options: { label: string }[];
}) => {
  const [state, dispatch] = React.useContext(Context);
  const [value, setValue] = useState(options[0].label);

  useEffect(() => {
    const obj: Payload = {};
    obj[id] = value;
    dispatch({ type: "UPDATE", payload: obj });
  }, [value]);

  return (
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-6 sm:col-span-3">
        {primary && (
          <label
            htmlFor={id}
            className="block text-sm font-medium text-gray-700"
          >
            {primary}
          </label>
        )}
        <select
          disabled={state.loading}
          value={state.data[id]}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          id={id}
          name={id}
          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        >
          {options &&
            options.map((opt, i) => {
              return <option key={i}>{opt.label}</option>;
            })}
        </select>
      </div>
    </div>
  );
};

export const SelectMultiStep = ({
  id,
  primary,
  options,
}: {
  id: string;
  primary?: string;
  options: { label: string; secondary?: string }[];
}) => {
  const [state, dispatch] = React.useContext(Context);
  const [checkedState, setCheckedState] = useState(
    options.map((opt) => {
      return {
        ...opt,
        selected: false,
      };
    })
  );

  const handleOnChange = (position: number) => {
    const updatedCheckedState = checkedState.map((item, index) => {
      return {
        ...item,
        selected: index === position ? !item.selected : item.selected,
      };
    });

    setCheckedState(updatedCheckedState);
  };

  useEffect(() => {
    const obj: Payload = {};
    obj[id] = checkedState
      .filter((ele) => ele.selected)
      .map((ele) => ele.label);
    dispatch({ type: "UPDATE", payload: obj });
  }, [checkedState]);

  return (
    <fieldset>
      {primary && (
        <>
          <legend className="sr-only">{primary}</legend>
          <div
            className="text-base font-medium text-gray-900"
            aria-hidden="true"
          >
            {primary}
          </div>
        </>
      )}
      <div className="mt-4 space-y-4">
        {options.map((item, idx) => {
          return (
            <div key={idx} className="flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id={idx.toString()}
                  name={item.label}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  onChange={() => handleOnChange(idx)}
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor={idx.toString()}
                  className="font-medium text-gray-700"
                >
                  {item.label}
                </label>
                {item.secondary && (
                  <p className="text-gray-500">{item.secondary}</p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </fieldset>
  );
};

interface VisualSelectionItem {
  value: string;
  label: string;
  description?: string;
  icon?: any;
  iconForeground: string;
  iconBackground: string;
}

export function VisualSelectStep({
  primary,
  next,
  id,
  items,
}: {
  id: string;
  primary?: string;
  next: () => void;
  items: VisualSelectionItem[];
}) {
  const [_, dispatch] = React.useReducer(reducer, initState);

  const selectOption = useCallback(
    (value: string) => {
      const obj: Payload = {};
      obj[id] = value;
      dispatch({ type: "UPDATE", payload: obj });
      next();
    },
    [id]
  );

  return (
    <>
      {primary && <Header primary={primary} />}
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0 ">
        {items.map((action, actionIdx) => (
          <div
            key={action.label}
            className={classNames(
              actionIdx === 0
                ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                : "",
              actionIdx === 1 ? "sm:rounded-tr-lg" : "",
              actionIdx === items.length - 2 ? "sm:rounded-bl-lg" : "",
              actionIdx === items.length - 1
                ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                : "",
              "group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
            )}
          >
            {action.icon && (
              <div>
                <span
                  className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    "inline-flex rounded-lg p-3 ring-4 ring-white"
                  )}
                >
                  <action.icon className="h-6 w-6" aria-hidden="true" />
                </span>
              </div>
            )}
            <div className="mt-8">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                {/* Extend touch target to entire panel */}
                <span
                  className="absolute inset-0 hover:cursor-pointer"
                  aria-hidden="true"
                  onClick={(e) => {
                    selectOption(action.label);
                  }}
                />
                {action.label}
              </h3>
              {action.description && (
                <p className="mt-2 text-sm text-gray-500">
                  {action.description}
                </p>
              )}
            </div>
            <span
              className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        ))}
      </div>
    </>
  );
}

export function VisualSelectStepCards({
  primary,
  next,
  id,
  items,
}: {
  id: string;
  primary?: string;
  next: (value?: any) => void;
  items: VisualSelectionItem[];
}) {
  const [state, dispatch] = useStepContext();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const selectOption = useCallback(
    (value: string) => {
      const obj: Payload = {};
      obj[id] = value;
      dispatch({ type: "UPDATE", payload: obj });
      setHasSubmitted(true);
    },
    [id, dispatch]
  );

  useEffect(() => {
    const value = state.data[id];
    if (hasSubmitted && !R.isNil(value)) {
      next(value);
    }
  }, [hasSubmitted, id, state, state.data, next]);

  return (
    <>
      {primary && <Header primary={primary} />}
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
      >
        {items.map((item) => (
          <li
            key={item.label}
            className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow hover:cursor-pointer border-2 border-transparent hover:bg-indigo-100"
            onClick={(e) => {
              selectOption(item.value);
            }}
          >
            <div className="flex flex-1 flex-col p-8">
              {item.icon && (
                <div
                  className={classNames(
                    "mx-auto flex h-20 w-20 items-center justify-center rounded-full",
                    item.iconBackground,
                    item.iconForeground
                  )}
                >
                  <item.icon className={"h-8 w-8"} aria-hidden="true" />
                </div>
              )}
              <h3 className="mt-6 text-sm font-medium text-gray-900">
                {item.label}
              </h3>
              <dl className="mt-1 flex flex-grow flex-col justify-between">
                <dt className="sr-only">Description</dt>
                <dd className="text-sm text-gray-500">{item.description}</dd>
              </dl>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}

export const AddCaseStudiesToMsgStep = ({
  id,
  primary,
  secondary,
  variant,
  successStories,
}: {
  id: string;
  primary: string;
  secondary?: string;
  variant?: { body: string };
  successStories: SuccessStory[];
}) => {
  const [state, dispatch] = useStepContext();

  const setEntryValue = (value: string) => {
    const obj: Payload = {};
    obj[id] = value;
    dispatch({ type: "UPDATE", payload: obj });
  };

  const loading = variant == undefined;
  let mainPrimary = "Generating Message";
  let mainSecondary: string | undefined = undefined;
  if (!loading) {
    mainPrimary = "Here is the message that was generated";
    mainSecondary =
      "This message was generated targeting a random contact, you will be able to refine the contact in future steps";
  }

  return (
    <>
      <Header primary={mainPrimary} secondary={mainSecondary} />
      <Variant body={variant?.body} loading={loading} />
      {!loading && (
        <>
          <Header primary={primary} secondary={secondary} />
          <CaseStudies studies={successStories} />
        </>
      )}
    </>
  );
};
