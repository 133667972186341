import SideLayout from "./SideLayout/index";
import { useEffect } from "react";
import * as Analytics from "../utils/analytics";
import { useSession } from "../utils/graphql";
import Spinner from "./Spinner";
import Modal, { ModalProvider, useModal } from "./Modal";
import OnboardingWizard from "./onboarding-wizard";

const OnboardModal = () => {
  const { session } = useSession();
  const [_, setOpen] = useModal();

  useEffect(() => {
    if (session.loading || !session.data.user) {
      return;
    }
    if (session.data.user.shouldOnboard) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [session, setOpen]);

  return (
    <Modal
      disableClose={true}
      className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-3/4 sm:p-6"
    >
      <OnboardingWizard />
    </Modal>
  );
};

export function ProtectedContainer({
  children,
}: {
  children?: React.ReactNode | React.ReactNode[];
}) {
  const { session } = useSession({ redirectTo: "/sign-in" });
  if (!session.loading && session.data.user == undefined) {
    return <LoadingSpinner />;
  }

  return <>{children}</>;
}

export function ProtectedLayout({
  children,
  heading,
}: {
  children?: React.ReactNode | React.ReactNode[];
  heading?: string;
}) {
  return (
    <ProtectedContainer>
      <Layout heading={heading}>{children}</Layout>;
    </ProtectedContainer>
  );
}

export function LoadingSpinner() {
  return (
    <div className="absolute inset-0 bg-white/90 flex items-center justify-center z-10">
      <div className="flex flex-col items-center">
        <Spinner className="!mx-auto mb-5 h-7 w-7" />
        <div className="text-gray-900 font-medium">Loading</div>
      </div>
    </div>
  );
}

export default function Layout({
  children,
  heading,
}: {
  children?: React.ReactNode | React.ReactNode[];
  heading?: string;
}) {
  const { session } = useSession();

  return (
    <>
      {session.loading && (
        <div className="absolute inset-0 bg-white/90 flex items-center justify-center z-10">
          <div className="flex flex-col items-center">
            <Spinner className="!mx-auto mb-5 h-7 w-7" />
            <div className="text-gray-900 font-medium">Loading</div>
          </div>
        </div>
      )}
      <SideLayout heading={heading}>{children}</SideLayout>
      <ModalProvider>
        <OnboardModal />
      </ModalProvider>
    </>
  );
}
