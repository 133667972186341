import React, { Fragment, useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import {
  LockClosedIcon,
  CogIcon,
  InboxIcon,
  ClipboardDocumentCheckIcon as ClipboardCopyIcon,
  PaperAirplaneIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";

import { StarIcon } from "@heroicons/react/24/solid";

import { classNames } from "../utils";
import { User } from "../utils/graphql";
import { Menu, Transition } from "@headlessui/react";
import {
  useSession,
  SEND_USING_IDENTITY,
  CREATE_REVIEW,
} from "../utils/graphql";
import { useMutation } from "@apollo/client";
import * as Analytics from "../utils/analytics";
import useClipboard from "react-use-clipboard";
import * as utils from "./utils";
import { useNotification } from "./Noification";
import { useRouter } from "next/router";
import TypeIt from "typeit-react";

export interface VariantProps {
  id?: string;
  body?: string;
  createdAt?: string;
  children?: React.ReactElement; // Action cluster
  review?: { body: string; value: number };
  allowSend?: boolean;
  allowEdit?: boolean;
  animateTyping?: boolean;
  loading?: boolean;
  allowRating?: boolean;
  allowCopy?: boolean;
}

const ReviewModal = () => {
  return <div>ENTER REVIEW</div>;
};

const Review = ({
  id,
  rating,
  review,
  onSetRating,
}: {
  id?: string;
  rating?: number;
  review?: string;
  onSetRating: (idx: number) => void;
}) => {
  const [currentHovered, setCurrentHovered] = useState(rating);

  useEffect(() => {
    setCurrentHovered(rating);
  }, [rating]);

  return (
    <div className="flex items-center xl:col-span-1">
      <div className="flex items-center">
        {[1, 2, 3, 4, 5].map((myRating) => (
          <StarIcon
            key={myRating}
            onMouseOver={() => {
              setCurrentHovered(myRating);
            }}
            onMouseOut={() => {
              setCurrentHovered(rating);
            }}
            onClick={() => {
              onSetRating(myRating);
            }}
            className={classNames(
              currentHovered && currentHovered >= myRating
                ? "text-yellow-400"
                : "text-gray-200 hover:text-yellow-400",
              "h-5 w-5 flex-shrink-0",
              "cursor-pointer"
            )}
            aria-hidden="true"
          />
        ))}
      </div>
    </div>
  );
};

export const Icon = ({ loading, user }: { loading?: boolean; user?: User }) => {
  if (loading) {
    return (
      <svg
        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
  }

  if (user) {
    return null;
  }

  return <LockClosedIcon className="w-5 h-5 mr-3 fill-current text-white" />;
};

const LoadingState = () => {
  return (
    <div className="animate-pulse flex space-x-4">
      <div className="flex-1 space-y-6 py-1">
        <div className="space-y-3">
          <div className="grid grid-cols-4 gap-4">
            <div className="h-4 bg-slate-700 col-span-1"></div>
          </div>
          <div className="h-4 bg-slate-700"></div>
          <div className="h-4 bg-slate-700"></div>
          <div className="h-4 bg-slate-700"></div>
          <div className="grid grid-cols-4 gap-4">
            <div className="h-4 bg-slate-700 col-span-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Variant({
  id,
  body,
  createdAt,
  children,
  review,
  allowSend,
  allowEdit,
  animateTyping,
  loading,
  allowRating,
  allowCopy,
}: VariantProps) {
  if (body === undefined) {
    body = "";
  }
  const router = useRouter();
  const [rating, setRating] = useState<{
    rating: number | undefined;
    review: string | undefined;
  }>({
    rating: review?.value,
    review: review?.body,
  });

  const [gmailSent, setGmailSent] = useState({
    draft: false,
    send: false,
  });
  const [isCopied, setCopied] = useClipboard(body, {
    // `isCopied` will go back to `false` after 1000ms.
    successDuration: 1000,
  });
  const [sendUsingIdentityMutation, { loading: sendLoadng }] =
    useMutation(SEND_USING_IDENTITY);
  const [_, notify] = useNotification();
  const { state: session } = useSession();

  const actionMenu = [];

  if (allowCopy) {
    actionMenu?.push({
      icon: (
        <ClipboardCopyIcon
          className="mr-3 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      ),
      label: isCopied ? "Copied!" : "Copy text",
      onClick: () => {
        setCopied();
        Analytics.track("VariantCopyClick");
      },
    });
  }

  if (allowEdit && id != undefined) {
    actionMenu?.push({
      icon: (
        <PencilIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
      ),
      label: "Edit",
      onClick: () => {
        // TODO: save on edit
        router.push(`/compositions/${id}`);
        Analytics.track("VariantEditClick");
      },
    });
  }

  if (id && allowSend && utils.candSendToGmail(session.data.user)) {
    actionMenu.push({
      icon: (
        <InboxIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
      ),
      label: gmailSent.draft ? "Sent to drafts" : "Send Draft To Gmail",
      onClick: async () => {
        if (gmailSent.draft) {
          return;
        }
        setGmailSent({
          ...gmailSent,
          draft: true,
        });
        Analytics.track("SendVariantToGmailClick");
        await sendUsingIdentityMutation({
          variables: {
            compositionId: id,
            action: "DRAFT",
            provider: "GOOGLE",
          },
        });

        notify({
          type: "NOTIFY",
          payload: {
            primary: "Variant sent to Gmail as draft",
            timeoutSecs: 2.5,
          },
        });
      },
    });
    actionMenu.push({
      icon: (
        <PaperAirplaneIcon
          className="mr-3 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      ),
      label: gmailSent.send ? "Sent using gmail!" : "Send Using Gmail",
      onClick: async () => {
        if (gmailSent.send) {
          return;
        }
        setGmailSent({
          ...gmailSent,
          send: true,
        });
        Analytics.track("SendVariantUsingGmailClick");
        await sendUsingIdentityMutation({
          variables: {
            compositionId: id,
            action: "SEND",
            provider: "GOOGLE",
          },
        });

        notify({
          type: "NOTIFY",
          payload: {
            primary: "Variant sent using Gmail as draft",
            timeoutSecs: 2.5,
          },
        });
      },
    });
  }

  const [createReviewMutation] = useMutation(CREATE_REVIEW);

  const onSetRating = async (newRating: number) => {
    setRating({
      ...rating,
      rating: newRating,
    });
    let trackProps: { [index: string]: string | number } = {
      rating: newRating,
    };

    if (id && newRating) {
      trackProps = { ...trackProps, id };
      await createReviewMutation({
        variables: {
          value: newRating,
          id: id,
        },
      });
    }

    Analytics.track("VariantRated", trackProps);
  };

  const Body = () => {
    if (loading) {
      return <LoadingState />;
    }

    if (animateTyping) {
      return (
        <TypeIt
          options={{
            waitUntilVisible: true,
            speed: 5,
            cursor: false,
            lifeLike: true,
          }}
        >
          <ReactMarkdown>{body as string}</ReactMarkdown>
        </TypeIt>
      );
    }
    return <ReactMarkdown>{body as string}</ReactMarkdown>;
  };

  return (
    <div className="border border-gray-200 rounded divide-y divide-gray-300">
      <div className="flex space-x-3 pt-2 px-3 pb-2 bg-gray-100">
        <div className="min-w-0 flex-1">
          {createdAt && (
            <p className="text-sm text-gray-500">Created: {createdAt}</p>
          )}
          {allowRating && (
            <Review
              rating={rating.rating}
              review={rating.review}
              onSetRating={onSetRating}
            />
          )}
        </div>
        <div className="flex flex-shrink-0 self-center">
          {actionMenu.length === 0 && <>&nbsp;</>}
          {actionMenu.length > 0 && (
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                  <span className="sr-only">Open options</span>
                  <CogIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {actionMenu &&
                      actionMenu.map(({ label, onClick, icon }, idx) => (
                        <Menu.Item key={idx}>
                          {({ active }) => (
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                onClick();
                              }}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex px-4 py-2 text-sm"
                              )}
                            >
                              {icon}
                              <span>{label}</span>
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          )}
        </div>
      </div>
      <div className="max-w-full text-lg px-6 py-4">
        <div className="max-w-full prose">
          <Body />
        </div>
        {children && <div className="px-4 py-4">{children}</div>}
      </div>
    </div>
  );
}
