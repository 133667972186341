import {
  UserGroupIcon,
  QueueListIcon,
  HomeIcon,
  WrenchScrewdriverIcon,
  DocumentTextIcon,
  NewspaperIcon,
  BuildingStorefrontIcon,
  ChatBubbleLeftIcon,
  ArrowDownOnSquareIcon,
} from "@heroicons/react/24/outline";

export const navigation = [
  {
    name: "Home",
    href: "/",
    current: false,
    requireSession: false,
    icon: HomeIcon,
    displayInNav: true,
  },
  // {
  //   name: "News Lead Scan",
  //   href: "/news-scan",
  //   icon: NewspaperIcon,
  //   current: false,
  //   requireSession: true,
  //   displayInNav: true,
  // },
  // {
  //   name: "Collected Contacts",
  //   href: "/prospects",
  //   icon: UserGroupIcon,
  //   current: false,
  //   requireSession: true,
  //   displayInNav: true,
  // },
  {
    name: "Previous Scans",
    href: "/scan-history",
    icon: QueueListIcon,
    current: false,
    requireSession: true,
    displayInNav: true,
  },
  {
    name: "Case Studies",
    current: false,
    href: "/case-studies",
    icon: DocumentTextIcon,
    requireSession: true,
    displayInNav: true,
  },
  {
    name: "Messages",
    href: "/messages",
    current: false,
    requireSession: true,
    icon: ChatBubbleLeftIcon,
    displayInNav: true,
  },
  {
    name: "Install Plugin",
    href: "https://chromewebstore.google.com/detail/mailmentor-sales-copilot/lmaejlipbkiphadkcbhmmpaoephkgmpj",
    current: false,
    requireSession: true,
    icon: ArrowDownOnSquareIcon,
    displayInNav: true,
  },
  //{
  //name: "Contacts",
  //href: "/contacts",
  //current: false,
  //icon: UserCircleIcon,
  //requireSession: true,
  //displayInNav: true,
  //},
  // {
  //   name: "Other Tools",
  //   current: false,
  //   requireSession: true,
  //   icon: WrenchScrewdriverIcon,
  //   displayInNav: true,
  //   subMenu: [
  // {
  //   name: "Company Directory",
  //   href: "/companies",
  //   //icon: BuildingStorefrontIcon,
  //   current: false,
  //   requireSession: true,
  //   displayInNav: true,
  // },
  // {
  //   name: "Lists",
  //   href: "/lists",
  //   //icon: QueueListIcon,
  //   current: false,
  //   requireSession: true,
  //   displayInNav: true,
  // },
  // {
  //   name: "Campaigns",
  //   href: "/campaigns",
  //   current: false,
  //   requireSession: true,
  //   displayInNav: true,
  // },
  // {
  //   name: "Generate Messages",
  //   href: "/personalization-wizard",
  //   current: false,
  //   requireSession: false,
  //   // icon: PencilIcon,
  //   displayInNav: true,
  // },
  // {
  //   name: "Message Optimizer",
  //   href: "/compose",
  //   current: false,
  //   requireSession: false,
  //   // icon: PencilIcon,
  //   displayInNav: true,
  // },
  // {
  //   name: "Sequence Builder",
  //   href: "/sequence-builder",
  //   current: false,
  //   requireSession: false,
  //   displayInNav: true,
  // },
  //   ],
  // },
];
