import React, { useMemo } from "react";
import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import { useRouter } from "next/router";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useSession } from "../../utils/graphql";
import * as R from "ramda";

interface NavItem {
  name: string;
  href?: string;
  current?: boolean;
  requireSession?: boolean;
  icon?: any;
  displayInNav?: boolean;
  subMenu?: NavItem[];
  isOpen?: boolean;
}

type MenuTreeProps = {
  nav?: NavItem[];
};

const isAnyActive = (currentPath: string, items?: NavItem[]): boolean => {
  if (R.isNil(items)) {
    return false;
  }

  for (let item of items) {
    if (item.href == currentPath) {
      console.log("isActive", true);
      return true;
    }
    if (item.subMenu && item.subMenu?.length > 0) {
      return isAnyActive(currentPath, item.subMenu);
    }
  }

  return false;
};

const MenuTree = ({ nav }: MenuTreeProps) => {
  const { state } = useSession();
  const router = useRouter();

  let visibleNav = useMemo(() => {
    if (R.isNil(nav)) {
      return [];
    }
    const myNav = nav.map((navItem) => {
      navItem.current = false;
      if (router.pathname == navItem.href) {
        navItem.current = true;
      }
      const isOpen = isAnyActive(router.pathname, navItem.subMenu);
      //const isOpen = true;
      return {
        ...navItem,
        isOpen,
      };
    });

    return myNav.filter((navItem) => {
      return (
        (navItem.displayInNav || navItem.current) &&
        (!navItem.requireSession || state.data.user != undefined)
      );
    });
  }, [nav, router.pathname, state.data.user]);

  return (
    <>
      {visibleNav.map((item, idx) =>
        item.subMenu?.length ? (
          <Disclosure key={idx} defaultOpen={item.isOpen}>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between items-center w-full px-2 py-2">
                  <div
                    className={classNames(
                      item.current
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group flex items-center text-sm font-medium rounded-md",
                      "hover:cursor-pointer"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </div>
                  <ChevronDownIcon
                    className={classNames(
                      "h-6 w-6 text-gray-400 group-hover:text-gray-500",
                      {
                        "rotate-180 transform": open,
                      }
                    )}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <div className="ml-10">
                    <MenuTree nav={item.subMenu} />
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ) : (
          <a
            key={idx}
            onClick={() => {
              if (item.href) {
                router.push(item.href);
              }
            }}
            className={classNames(
              item.current
                ? "bg-gray-100 text-gray-900"
                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
              "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
              "hover:cursor-pointer"
            )}
          >
            {item.icon && (
              <item.icon
                className={classNames(
                  item.current
                    ? "text-gray-500"
                    : "text-gray-400 group-hover:text-gray-500",
                  "mr-3 flex-shrink-0 h-6 w-6"
                )}
                aria-hidden="true"
              />
            )}
            {item.name}
          </a>
        )
      )}
    </>
  );
};

export default MenuTree;
