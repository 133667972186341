import { useState, useEffect } from "react";
import * as Analytics from "../utils/analytics";
import {
  ClipboardDocumentListIcon as ClipboardListIcon,
  CheckIcon,
  UserGroupIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../utils";

import { useMutation, useQuery } from "@apollo/client";
import { useRouter } from "next/router";
import { useSession, CREATE_METADATA, PRICING_QUERY } from "../utils/graphql";
import {
  StepsContainer,
  StepContainer,
  EntryStep,
  SelectMultiStep,
  VisualSelectStepCards,
} from "./onboarding-steps";
import { Wizard, Steps, Step, withWizard } from "react-albus";
import NewsScan from "./NewsScan";
import Mark from "../assets/svg/mark.svg";
import Image from "next/image";
import PricingSingle from "./SinglePricing";
import LogoCloud from "./LogoCloud";
import * as R from "ramda";

const orgRoleOptions = [
  { label: "Small Business Owner" },
  { label: "Startup Founder" },
  { label: "Executive or VP" },
  { label: "Sales Professional" },
  { label: "Marketing Professional" },
  { label: "Other" },
];

const orgSizeOptions = [
  { label: "1 - 10 employees" },
  { label: "11 - 50 employees" },
  { label: "51 - 100 employees" },
  { label: "101 - 250 employees" },
  { label: "251 - 1,000 employees" },
  { label: "More than 1,000 employees" },
];

const featurePaths = [
  { label: "Find Leads", icon: UserGroupIcon, href: "/" },
  {
    label: "Generate Emails",
    icon: PencilSquareIcon,
    href: "/sequence-builder",
  },
];

const featurePathsMap = R.indexBy(R.prop("label"), featurePaths);

const WizardNav3 = withWizard(({ wizard }) => {
  const { steps } = wizard;
  const currentIdx = steps.findIndex((step) => step.id === wizard.step.id);
  return (
    <nav className="flex items-center justify-center" aria-label="Progress">
      <ol role="list" className="flex items-center">
        {steps.map((step, stepIdx) => (
          <li
            key={step.id}
            className={classNames(
              stepIdx !== steps.length - 1 ? "pr-8 sm:pr-20" : "",
              "relative",
            )}
          >
            {currentIdx > stepIdx ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-indigo-600" />
                </div>
                <span className="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-900">
                  <CheckIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.id}</span>
                </span>
              </>
            ) : stepIdx === currentIdx ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <span
                  className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white"
                  aria-current="step"
                >
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-indigo-600"
                    aria-hidden="true"
                  />

                  <span className="sr-only">{step.id}</span>
                </span>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <span className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.id}</span>
                </span>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
});

const WizardNav2 = withWizard(({ wizard }) => {
  const currentIdx = wizard.steps.findIndex(
    (step) => step.id === wizard.step.id,
  );

  return (
    <nav className="flex items-center justify-center" aria-label="Progress">
      <p className="text-sm font-medium">
        Step {wizard.steps.findIndex((step) => step.id === wizard.step.id) + 1}{" "}
        of {wizard.steps.length}
      </p>
      <ol role="list" className="ml-8 flex items-center space-x-5">
        {wizard.steps.map((step, idx) => (
          <li key={step.id}>
            {idx < currentIdx ? (
              <a
                href={step.id}
                className="block h-2.5 w-2.5 rounded-full bg-indigo-600 hover:bg-indigo-900"
              >
                <span className="sr-only">{step.id}</span>
              </a>
            ) : idx === currentIdx ? (
              <a
                href={step.id}
                className="relative flex items-center justify-center"
                aria-current="step"
              >
                <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                  <span className="h-full w-full rounded-full bg-indigo-200" />
                </span>
                <span
                  className="relative block h-2.5 w-2.5 rounded-full bg-indigo-600"
                  aria-hidden="true"
                />
                <span className="sr-only">{step.id}</span>
              </a>
            ) : (
              <a
                href={step.id}
                className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400"
              >
                <span className="sr-only">{step.id}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
});

const WizardNav = withWizard(({ wizard }) => {
  return (
    <aside className="px-2 py-6 sm:px-6 lg:col-span-3 lg:px-0 lg:py-0">
      <nav className="space-y-1">
        {wizard.steps.map((item, idx) => (
          <div
            key={item.id}
            className={classNames(
              wizard.step.id === item.id
                ? "bg-gray-50 text-indigo-700 hover:bg-white hover:text-indigo-700"
                : "text-gray-900 hover:bg-gray-50 hover:text-gray-900",
              "group flex items-center rounded-md px-3 py-2 text-sm font-medium",
            )}
          >
            <ClipboardListIcon
              className={classNames(
                wizard.step.id === item.id
                  ? "text-indigo-500 group-hover:text-indigo-500"
                  : "text-gray-400 group-hover:text-gray-500",
                "-ml-1 mr-3 h-6 w-6 flex-shrink-0",
              )}
              aria-hidden="true"
            />
            <span className="truncate">{item.id}</span>
          </div>
        ))}
      </nav>
    </aside>
  );
});

export default function OnboardingWizard() {
  const { state, dispatch } = useSession();
  const [data, setData] = useState({
    version: "3",
    label: "ONBOARDING",
  });
  const router = useRouter();
  const [createMetadataMutation] = useMutation(CREATE_METADATA);
  const { data: pricingData } = useQuery(PRICING_QUERY);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Analytics.track("OnboardingWizardStart");
  }, []);

  const submitData = async () => {
    setLoading(true);
    await createMetadataMutation({
      variables: {
        data: JSON.stringify(data),
        metaDataType: "ACCOUNT",
      },
    });
  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-full">
          <div className="text-center">
            <Image
              alt="MailMentor Logo"
              src={Mark}
              width={60}
              height={60}
              className="mx-auto h-12 w-auto"
            />
          </div>
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Onboarding
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full">
          {/* @ts-ignore */}
          <Wizard>
            <StepsContainer
              setWizardValue={(val) => {
                setData({
                  ...data,
                  ...val,
                });
              }}
            >
              <div className="p-10 lg:gap-x-5">
                <div className="space-y-6 sm:px-6 lg:px-0">
                  <WizardNav3 />
                  <Steps>
                    <Step
                      id="Step 1 - Organization Name"
                      render={({ next }) => (
                        <StepContainer
                          primary={"What is the name of your organization"}
                          next={() => {
                            Analytics.track("OnboardingWizardStepComplete", {
                              step: 1,
                            });
                            next();
                          }}
                        >
                          <EntryStep id="organizationName" />
                        </StepContainer>
                      )}
                    />
                    <Step
                      id="Step 2 - Role"
                      render={({ next }) => (
                        <VisualSelectStepCards
                          id="role"
                          items={orgRoleOptions.map((myRole) => {
                            return {
                              ...myRole,
                              value: myRole.label,
                              iconForeground: "text-grey-700",
                              iconBackground: "bg-grey-50",
                            };
                          })}
                          primary="What is your role within your organization?"
                          next={(orgSize: string) => {
                            Analytics.track("OnboardingWizardStepComplete", {
                              step: 2,
                              label: orgSize,
                            });
                            next();
                          }}
                        />
                      )}
                    />
                    <Step
                      id="Step 3 - Organization Size"
                      render={({ next }) => (
                        <VisualSelectStepCards
                          id="organizationSize"
                          items={orgSizeOptions.map((myRole) => {
                            return {
                              ...myRole,
                              value: myRole.label,
                              iconForeground: "text-grey-700",
                              iconBackground: "bg-grey-50",
                            };
                          })}
                          primary="Organization Size"
                          next={(myRole: string) => {
                            Analytics.track("OnboardingWizardStepComplete", {
                              step: 3,
                              label: myRole,
                            });
                            next();
                          }}
                        />
                      )}
                    />
                    <Step
                      id="Step 4 - Goals"
                      render={({ next }) => (
                        <VisualSelectStepCards
                          id="goals"
                          items={featurePaths.map((feature) => {
                            return {
                              ...feature,
                              value: feature.label,
                              iconForeground: "text-grey-700",
                              iconBackground: "bg-grey-50",
                            };
                          })}
                          primary="What you hoping to accomplish by using MailMentor?"
                          next={(selection: string) => {
                            submitData();
                            const selectedItem = featurePathsMap[selection];
                            router.push(selectedItem.href);
                            Analytics.track("OnboardingWizardStepComplete", {
                              step: 4,
                              label: selectedItem.label,
                            });
                            Analytics.track("OnboardingWizardComplete");
                            dispatch({
                              type: "UPDATE",
                              payload: {
                                user: {
                                  ...(state.data.user as any),
                                  shouldOnboard: false,
                                },
                              },
                            });
                          }}
                        />
                      )}
                    />
                  </Steps>
                </div>
              </div>
            </StepsContainer>
          </Wizard>
        </div>
      </div>
    </>
  );
}
