import { User } from "../../utils/graphql";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export { dayjs };

export const candSendToGmail = (user: User | undefined) => {
  return user?.sendableIdentities.includes("GOOGLE");
};
