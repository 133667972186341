import {
  ReactNode,
  Fragment,
  useContext,
  useState,
  createContext,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { classNames } from "../utils";

export const Context = createContext<any>([false, () => {}]);

export const ModalProvider = ({ children }: { children?: ReactNode }) => {
  const [open, setOpen] = useState(false);

  return (
    <Context.Provider value={[open, setOpen]}>{children}</Context.Provider>
  );
};

export const useModal = () => {
  return useContext(Context);
};

export default function Modal({
  children,
  className,
  disableClose = false,
}: {
  children?: ReactNode;
  className?: string;
  disableClose?: boolean;
}) {
  const [open, setOpen] = useContext(Context);

  className =
    className ||
    "relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6";

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={(val) => {
          if (disableClose) return;
          setOpen(val);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={className}>{children}</Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
