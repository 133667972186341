import { useState, useEffect } from "react";
import { CREATE_SUCCESS_STORY, DELETE_SUCCESS_STORY } from "../utils/graphql";
import { useMutation } from "@apollo/client";
import * as Analytics from "../utils/analytics";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/24/solid";
import { useRouter } from "next/router";
import { useNotification } from "./Noification";

export interface SuccessStory {
  id: string;
  companyName: string;
  outcome: string;
}

export default function CaseStudies({ studies }: { studies: SuccessStory[] }) {
  const [newStudy, setNewStudy] = useState<{
    companyName: string | undefined;
    outcome: string | undefined;
  }>({ companyName: undefined, outcome: undefined });
  const [createSuccessStoryMutation, { loading }] =
    useMutation(CREATE_SUCCESS_STORY);
  const [deleteCaseStudyMutation, { loading: deleteLoading }] =
    useMutation(DELETE_SUCCESS_STORY);
  const [localStudies, setLocalStudies] = useState(studies);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const router = useRouter();
  const [_, notify] = useNotification();

  const canSave =
    !loading &&
    newStudy.companyName != undefined &&
    newStudy.companyName != "" &&
    newStudy.outcome != undefined &&
    newStudy.outcome != "";

  useEffect(() => {
    if (localStudies.length == 0) {
      setIsAddingNew(true);
    }
  }, [localStudies]);

  const deleteCaseStudy = async (id: string) => {
    if (deleteLoading) {
      // Maybe log
      return;
    }
    await deleteCaseStudyMutation({
      variables: {
        id: id,
      },
    });

    const newStud = localStudies.filter((std) => std.id != id);
    setLocalStudies(newStud);
    Analytics.track("CaseStudyDeleted");
  };

  const onSubmit = async () => {
    if (canSave) {
      try {
        const { data } = await createSuccessStoryMutation({
          variables: newStudy,
        });

        setNewStudy({ companyName: "", outcome: "" });

        const newStud = localStudies.concat([
          data.createSuccessStory as SuccessStory,
        ]);
        setLocalStudies(newStud);
        Analytics.track("CaseStudyAdded");
        setIsAddingNew(false);
      } catch (err: any) {
        if (
          err.message ==
          `Unexpected error value: "Account has reached its max usage for feature. Please upgrade."`
        ) {
          notify({
            type: "NOTIFY",
            payload: {
              primary: "You need to have a plan to user the AI generator.",
              timeoutSecs: 5,
            },
          });
          router.push("/select-plan");
          return;
        }
        throw err;
      }
    }
  };

  return (
    <>
      <ul
        role="list"
        className="border border-gray-200 rounded-md divide-y divide-gray-200"
      >
        {localStudies.map((study, idx) => {
          return (
            <li
              className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
              key={idx}
            >
              <div className="w-0 flex-1 flex items-center">
                <span className="ml-2 flex-1 w-0 truncate">
                  We helped {study.companyName} {study.outcome}
                </span>
                <MinusCircleIcon
                  className="w-5 h-5 fill-current text-indigo-500"
                  onClick={() => {
                    deleteCaseStudy(study.id);
                  }}
                />
                {localStudies.length - 1 == idx && !isAddingNew && (
                  <PlusCircleIcon
                    className="w-5 h-5 fill-current text-indigo-500"
                    onClick={() => {
                      setIsAddingNew(true);
                    }}
                  />
                )}
              </div>
            </li>
          );
        })}
        {isAddingNew && (
          <li
            className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
            key={"add-new"}
          >
            <div className="w-0 flex-1 flex items-center">
              <span className="ml-2 flex-1 w-0 truncate">
                We helped{" "}
                <input
                  className="border-b-2 border-gray-300 p-2"
                  placeholder="LuthorCorp"
                  value={newStudy.companyName}
                  onChange={(e) => {
                    setNewStudy({
                      ...newStudy,
                      companyName: e.target.value,
                    });
                  }}
                />{" "}
                <input
                  className="border-b-2 border-gray-300 p-2"
                  placeholder="aquire 20% more kryptonite"
                  value={newStudy.outcome}
                  onChange={(e) => {
                    setNewStudy({
                      ...newStudy,
                      outcome: e.target.value,
                    });
                  }}
                />{" "}
              </span>
              <button
                disabled={!canSave}
                onClick={onSubmit}
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25"
              >
                Save Case Study
              </button>
            </div>
          </li>
        )}
      </ul>
    </>
  );
}
