import { Fragment, useState } from "react";
import Image from "next/image";
import { Transition, Dialog } from "@headlessui/react";
import { useRouter } from "next/router";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { classNames, getGravitar } from "../../utils";
import { useSession } from "../../utils/graphql";
import TopBanner from "./../TopBanner";
import MarkDark from "../../assets/svg/mark.svg";
import MarkLockupDark from "../../assets/svg/mark-lockup-horizontal-dark.svg";
import { useNotification } from "./../Noification";

import { ModalProvider } from "./../Modal";

import { navigation } from "./config";
import MenuTree from "./MenuTree";

export function RawLayout({
  children,
}: {
  children?: React.ReactNode | React.ReactNode[];
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { state, signOut } = useSession();
  const [_, notify] = useNotification();
  const router = useRouter();

  const handleSignout = (e: React.MouseEvent) => {
    e.preventDefault();
    signOut();
    notify({
      type: "NOTIFY",
      payload: { primary: "User signed out", timeoutSecs: 2.5 },
    });
    router.push("/");
  };

  const userNavigation: { name: string; href: string; onClick?: any }[] = [];

  const user = state.data.user;

  let fullName: string | undefined;
  let email: string | undefined;
  if (user) {
    fullName = user.firstname + " " + user.lastname;
    email = user.email;
  }

  const pages = navigation.map((navItem) => {
    navItem.current = false;
    if (router.pathname == navItem.href) {
      navItem.current = true;
    }
    return navItem;
  });

  let nav = pages;

  const profileImgSrc = getGravitar(user?.email);

  const profileCluster = {
    userName: user ? user.firstname + " " + user.lastname : undefined,
    email: user ? user.email : undefined,
    text: user ? "View Account" : "Sign In",
    imgSrc: profileImgSrc,
    href: user ? "/account" : "/sign-in",
  };

  return (
    <ModalProvider>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 items-center justify-center px-4">
                      <Image
                        alt="MailMentor Logo"
                        src={MarkDark}
                        height={50}
                        className="w-auto cursor-pointer"
                      />
                    </div>
                    <nav className="mt-5 space-y-1 px-2">
                      {nav.map((item) => (
                        <a
                          key={item.name}
                          onClick={() => {
                            router.push(item.href!);
                          }}
                          className={classNames(
                            item.current
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md",
                            "hover:cursor-pointer"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                  <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                    <a
                      className="group block flex-shrink-0 hover:cursor-pointer"
                      onClick={() => {
                        router.push(profileCluster.href);
                      }}
                    >
                      <div className="flex items-center">
                        <div className="mt-2">
                          <Image
                            className="inline-block h-10 w-10 rounded-full"
                            src={profileCluster.imgSrc}
                            alt="Profile Image"
                            height={40}
                            width={40}
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                            {profileCluster.userName}
                          </p>
                          <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                            {profileCluster.text}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center justify-center px-4">
                <Image
                  alt="MailMentor Logo Lockup"
                  src={MarkLockupDark}
                  height={100}
                  className="w-auto cursor-pointer"
                />
              </div>
              <nav className="mt-5 flex-1 space-y-1 bg-white px-2">
                <MenuTree nav={nav} />
              </nav>
            </div>
            <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
              <a
                className="group block w-full flex-shrink-0 hover:cursor-pointer"
                onClick={() => {
                  router.push(profileCluster.href);
                }}
              >
                <div className="flex items-center">
                  <div className="mt-2">
                    <Image
                      className="inline-block h-9 w-9 rounded-full"
                      src={profileCluster.imgSrc}
                      alt="Profile Image"
                      width={40}
                      height={40}
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                      {profileCluster.userName}
                    </p>
                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                      {profileCluster.text}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1">
            {router.pathname != "/onboard" &&
              state.data.user?.shouldOnboard && (
                <TopBanner
                  note="Completing the onboarding will help improve generations."
                  noteBold="Finish Onboarding"
                  cta="Onboard Now"
                  href="/onboard"
                />
              )}
            {children}
          </main>
        </div>
      </div>
    </ModalProvider>
  );
}

export default function Layout({
  children,
  heading,
}: {
  children?: React.ReactNode | React.ReactNode[];
  heading?: string;
}) {
  const router = useRouter();

  const pages = navigation.map((navItem) => {
    navItem.current = false;
    if (router.pathname == navItem.href) {
      navItem.current = true;
    }
    return navItem;
  });

  const pageName = heading || pages.filter((i) => i.current)[0]?.name;

  return (
    <RawLayout>
      <div className="py-6">
        {pageName && (
          <div className="mx-auto max-w-full px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">{pageName}</h1>
          </div>
        )}
        <div className="mx-auto max-w-full px-4 sm:px-6 md:px-8">
          <div className="py-4">{children}</div>
        </div>
      </div>
    </RawLayout>
  );
}
